import { Button } from '@clevergy/ui/components/Button';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { Modal } from '@clevergy/ui/components/Modal';
import { useMutation } from '@tanstack/react-query';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppConfigurationStore } from 'stores/appConfigurationStore';

export type DeleteAccountModalProps = {
  isOpen: boolean;
  onCloseModal: () => void;
  onAccountDeleted: () => void;
};

export const DeleteAccountModal: FC<DeleteAccountModalProps> = ({
  isOpen,
  onCloseModal,
  onAccountDeleted,
}) => {
  const { t } = useTranslation();

  const { api } = useApiContext();
  const { authedUser } = useAuthContext();

  const resetMultiTenant = useAppConfigurationStore(
    (state) => () => state.setTenantId(undefined),
  );

  const userId = authedUser?.uid;

  const deleteUserMutation = useMutation({
    mutationFn: (userId: string) =>
      api.users.createDeleteUserAccountRequest({ userId }),
  });

  const onDeleteAccountClick = async () => {
    if (!userId) {
      onCloseModal();
      return;
    }
    await deleteUserMutation.mutateAsync(userId);
    resetMultiTenant();
    onAccountDeleted();
  };

  return (
    <Modal isOpen={isOpen} onClose={() => onCloseModal()}>
      <div className="my-8 flex flex-col items-center gap-4 text-center">
        <div className="text-red-600">
          <FontIcon
            name="error"
            size={56}
            variant="filled"
            circleBackgroundClass="bg-red-100"
          />
        </div>
        <p className="text-xl font-semibold text-center py-4">
          {t('account.deleteAccountModal.title')}
        </p>
        <p className="text-base text-neutral-700 text-center px-4">
          {t('account.deleteAccountModal.message')}
        </p>
      </div>

      <Button
        fullWidth
        variant="filled"
        colorScheme="danger"
        onClick={onDeleteAccountClick}
        busy={deleteUserMutation.isPending}
        disabled={deleteUserMutation.isPending}
      >
        {t('account.deleteAccountModal.confirm')}
      </Button>
    </Modal>
  );
};

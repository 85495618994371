import { ClevergyEventTypes, useClevergyEvent } from '@clevergy/event-bus';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { useAnalyticsContext } from 'context/AnalyticsContext';
import { useAuthContext } from 'context/AuthContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const HouseSettingsPage: FC = () => {
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const { track } = useAnalyticsContext();
  const { selectedHouse } = useSelectedHouse();

  // Listen to the event that fires clevergy-house-settings
  useClevergyEvent(ClevergyEventTypes.HOUSE_SETTINGS_SAVED, (event) => {
    track({
      event_type: 'house_settings_answered',
      event_properties: {
        page: 'profile',
        cups_available: event.data.cupsAvailable,
        house_settings_filled: event.data.houseSettingsFilled,
      },
    });
  });

  // Listen to the event that fires clevergy-house-settings
  useClevergyEvent(ClevergyEventTypes.HOUSE_SETTINGS_NOT_SAVED, (event) => {
    track({
      event_type: 'house_settings_not_saved',
      event_properties: {
        page: 'profile',
        cups_available: event.data.cupsAvailable,
        house_settings_filled: event.data.houseSettingsFilled,
      },
    });
  });

  return (
    <Page navbarLayoutContentMobileBackground="white">
      <PageHeader
        backButton
        title={t('houseSettings.title') as string}
        withHousePicker
        hideTitleAndBackButtonForNonMobile
      />

      <Wrapper>
        {(!token || !selectedHouse) && (
          <div className="fixed inset-0 flex items-center justify-center">
            <LoadingIndicator />
          </div>
        )}
        {token && selectedHouse && (
          <>
            <div className="flex flex-col gap-4">
              <div>
                <h3 className="text-clevergy-moduleHeaderTitle text-xl font-semibold mb-2">
                  {t('houseSettings.myAccount.configureYourHouseTitle')}
                </h3>
                <p>{t('houseSettings.myAccount.configureYourHouseContent')}</p>
              </div>
              <clevergy-house-settings
                data-token={token}
                data-house-id={selectedHouse.houseId}
              ></clevergy-house-settings>
            </div>
          </>
        )}
      </Wrapper>
    </Page>
  );
};

import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Alert } from '@clevergy/ui/components/Alert';
import { Button } from '@clevergy/ui/components/Button';
import { InputText } from '@clevergy/ui/components/InputText';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { useAuthContext } from 'context/AuthContext';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type SignupFormValues = {
  password: string;
  repeatPassword: string;
  customError?: string;
};

export const ChangePasswordPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authedUser } = useAuthContext();

  const {
    register,
    getValues,
    handleSubmit,
    setError,
    clearErrors,
    formState,
  } = useForm<SignupFormValues>();

  const onSubmit = async (data: SignupFormValues) => {
    try {
      await FirebaseAuthentication.updatePassword({
        newPassword: data.password,
      });
      navigate('/logout', { replace: true });
    } catch (error: unknown) {
      setError('customError', {
        type: 'serverSideError',
        message: t('changePassword.errors.unknown') as string,
      });
    }
  };

  return (
    <Page>
      <PageHeader title={t('changePassword.title') as string} />
      <Wrapper>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-4 h-full p-safe"
        >
          <input hidden value={authedUser?.email || ''} autoComplete="email" />
          <InputText
            id="password"
            type="password"
            label={t('changePassword.password.label') as string}
            showPasswordToggleButton
            autoComplete="new-password"
            placeholder={t('changePassword.password.placeholder')}
            {...register('password', {
              required: t('changePassword.password.required') as string,
              minLength: {
                value: 6,
                message: t('changePassword.password.minLength') as string,
              },
            })}
            error={formState.errors.password?.message as string}
          />

          <InputText
            id="repeatPassword"
            type="password"
            label={t('changePassword.repeatPassword.label') as string}
            showPasswordToggleButton
            autoComplete="new-password"
            placeholder={t('changePassword.repeatPassword.placeholder')}
            {...register('repeatPassword', {
              required: t('changePassword.repeatPassword.required') as string,
              validate: (value) =>
                value === getValues('password') ||
                (t('changePassword.repeatPassword.invalid') as string),
              minLength: {
                value: 6,
                message: t('changePassword.repeatPassword.minLength') as string,
              },
              deps: ['password'],
            })}
            error={formState.errors.repeatPassword?.message as string}
          />

          {formState.errors.customError && (
            <Alert
              type="error"
              text={formState.errors.customError?.message as string}
            />
          )}

          <div className="grow" />

          <Button
            type="submit"
            variant="filled"
            fullWidth
            busy={formState.isSubmitting}
            onClick={() => clearErrors()}
          >
            {t('changePassword.change')}
          </Button>
        </form>
      </Wrapper>
    </Page>
  );
};

/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { TenantUserIntegration } from './TenantUserIntegration';
import {
  TenantUserIntegrationFromJSON,
  TenantUserIntegrationFromJSONTyped,
  TenantUserIntegrationToJSON,
  TenantUserIntegrationToJSONTyped,
} from './TenantUserIntegration';

/**
 *
 * @export
 * @interface AppUserSetUp
 */
export interface AppUserSetUp {
  /**
   *
   * @type {Array<string>}
   * @memberof AppUserSetUp
   */
  houses?: Array<string>;
  /**
   *
   * @type {Array<TenantUserIntegration>}
   * @memberof AppUserSetUp
   */
  userIntegrations?: Array<TenantUserIntegration>;
}

/**
 * Check if a given object implements the AppUserSetUp interface.
 */
export function instanceOfAppUserSetUp(value: object): value is AppUserSetUp {
  return true;
}

export function AppUserSetUpFromJSON(json: any): AppUserSetUp {
  return AppUserSetUpFromJSONTyped(json, false);
}

export function AppUserSetUpFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AppUserSetUp {
  if (json == null) {
    return json;
  }
  return {
    houses: json['houses'] == null ? undefined : json['houses'],
    userIntegrations:
      json['userIntegrations'] == null
        ? undefined
        : (json['userIntegrations'] as Array<any>).map(
            TenantUserIntegrationFromJSON,
          ),
  };
}

export function AppUserSetUpToJSON(json: any): AppUserSetUp {
  return AppUserSetUpToJSONTyped(json, false);
}

export function AppUserSetUpToJSONTyped(
  value?: AppUserSetUp | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    houses: value['houses'],
    userIntegrations:
      value['userIntegrations'] == null
        ? undefined
        : (value['userIntegrations'] as Array<any>).map(
            TenantUserIntegrationToJSON,
          ),
  };
}

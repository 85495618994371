/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface UserDataConsent
 */
export interface UserDataConsent {
  /**
   *
   * @type {string}
   * @memberof UserDataConsent
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UserDataConsent
   */
  surname?: string;
  /**
   *
   * @type {string}
   * @memberof UserDataConsent
   */
  dni: string;
  /**
   *
   * @type {string}
   * @memberof UserDataConsent
   */
  cups: string;
}

/**
 * Check if a given object implements the UserDataConsent interface.
 */
export function instanceOfUserDataConsent(
  value: object,
): value is UserDataConsent {
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('dni' in value) || value['dni'] === undefined) return false;
  if (!('cups' in value) || value['cups'] === undefined) return false;
  return true;
}

export function UserDataConsentFromJSON(json: any): UserDataConsent {
  return UserDataConsentFromJSONTyped(json, false);
}

export function UserDataConsentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserDataConsent {
  if (json == null) {
    return json;
  }
  return {
    name: json['name'],
    surname: json['surname'] == null ? undefined : json['surname'],
    dni: json['dni'],
    cups: json['cups'],
  };
}

export function UserDataConsentToJSON(json: any): UserDataConsent {
  return UserDataConsentToJSONTyped(json, false);
}

export function UserDataConsentToJSONTyped(
  value?: UserDataConsent | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    name: value['name'],
    surname: value['surname'],
    dni: value['dni'],
    cups: value['cups'],
  };
}

import { setupApplication } from './setupApplication';
import '@clevergy/modules/src/types/clevergy-modules.d.ts';
import * as Sentry from '@sentry/browser';
import { sentrySettings } from 'constants/sentry';

if (!import.meta.env.DEV) {
  console.log('Sentry is disabled in dev mode');

  Sentry.init({
    dsn: sentrySettings.SENTRY_DSN_APPS,
  });
}

setupApplication();

console.log('Clevergy Application loaded!');

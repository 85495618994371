import { tenantsAssetsBaseUrl } from '@clevergy/shared/constants/default';
import { useTenantSettings } from 'context/TenantSettingsContext';
import { FC } from 'react';

export type TenantLogoProps = {
  type?: 'default' | 'small' | 'favicon';
  className?: string;
  width?: number;
  height?: number;
};

export const TenantLogo: FC<TenantLogoProps> = (props) => {
  const { type = 'default', className, width, height } = props;

  const { tenantId, name } = useTenantSettings();

  const src = `${tenantsAssetsBaseUrl}/${tenantId}/${
    type === 'small'
      ? 'logo-small.png'
      : type === 'favicon'
        ? 'favicon.png'
        : 'logo.png'
  }`;

  return (
    <img
      src={src}
      alt={name}
      className={className}
      width={width}
      height={height}
    />
  );
};

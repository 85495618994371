import { Browser } from '@capacitor/browser';
import { ClevergyEventTypes } from '@clevergy/event-bus';
import { useClevergyEvent } from '@clevergy/event-bus';
import { splitDisplayName } from '@clevergy/utils/format/splitDisplayName';
import { Page } from 'components/Page';
import { useAuthContext } from 'context/AuthContext';
import { useTenantSettings } from 'context/TenantSettingsContext';
import { useUserSetupContext } from 'context/UserSetupContext';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export type DatadisIntegrationPageProps = {
  // TODO: Add props
};

export const DatadisIntegrationPage: FC<DatadisIntegrationPageProps> = () => {
  const { t } = useTranslation();
  const { refetchSetup } = useUserSetupContext();
  const { token, authedUser } = useAuthContext();
  const { tenantId, legalName, dpoEmail } = useTenantSettings();
  const navigate = useNavigate();

  const handleSkip = useCallback(() => {
    if (window.confirm(t('smartmeter.skipConfirmation') as string)) {
      navigate('/');
    }
  }, [navigate, t]);

  const handleComplete = useCallback(async () => {
    await refetchSetup();
    navigate('/');
  }, [navigate, refetchSetup]);

  useClevergyEvent(
    ClevergyEventTypes.INTEGRATION_SMARTMETER_SKIPPED,
    handleSkip,
  );

  useClevergyEvent(
    ClevergyEventTypes.INTEGRATION_SMARTMETER_COMPLETED,
    handleComplete,
  );

  useClevergyEvent(ClevergyEventTypes.DATA_CONSENT_SKIPPED, handleSkip);
  useClevergyEvent(ClevergyEventTypes.DATA_CONSENT_COMPLETED, handleComplete);

  useClevergyEvent(
    ClevergyEventTypes.INTEGRATION_SMARTMETER_REQUEST_URL_OPENING,
    (event) => {
      const { url } = event.data;
      if (!url) {
        throw new Error('No URL provided');
      }
      Browser.open({ url });
    },
  );

  const { firstName, lastName } = splitDisplayName(authedUser?.displayName);

  return (
    <Page navbarLayoutContentMobileBackground="white">
      {token && dpoEmail && (
        <>
          {tenantId === 'selectra' ? (
            <clevergy-data-consent
              data-token={token}
              data-consent-name={legalName}
              data-opt-out-email={dpoEmail}
              data-user-firstname={firstName}
              data-user-lastname={lastName}
              data-companies-enabled={'true'}
              data-allow-skipping={'true'}
            />
          ) : (
            <clevergy-integration-smartmeter
              data-token={token}
              data-consent-name={legalName}
              data-opt-out-email={dpoEmail}
              data-user-firstname={firstName}
              data-user-lastname={lastName}
              data-companies-enabled={'true'}
              data-allow-skipping={'true'}
              data-delegate-url-opening={'true'}
            />
          )}
        </>
      )}
    </Page>
  );
};

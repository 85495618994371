/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface AuthorizeUserRequest
 */
export interface AuthorizeUserRequest {
  /**
   *
   * @type {string}
   * @memberof AuthorizeUserRequest
   */
  houseId: string;
  /**
   *
   * @type {string}
   * @memberof AuthorizeUserRequest
   */
  userAuthorizedEmail: string;
}

/**
 * Check if a given object implements the AuthorizeUserRequest interface.
 */
export function instanceOfAuthorizeUserRequest(
  value: object,
): value is AuthorizeUserRequest {
  if (!('houseId' in value) || value['houseId'] === undefined) return false;
  if (
    !('userAuthorizedEmail' in value) ||
    value['userAuthorizedEmail'] === undefined
  )
    return false;
  return true;
}

export function AuthorizeUserRequestFromJSON(json: any): AuthorizeUserRequest {
  return AuthorizeUserRequestFromJSONTyped(json, false);
}

export function AuthorizeUserRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AuthorizeUserRequest {
  if (json == null) {
    return json;
  }
  return {
    houseId: json['houseId'],
    userAuthorizedEmail: json['userAuthorizedEmail'],
  };
}

export function AuthorizeUserRequestToJSON(json: any): AuthorizeUserRequest {
  return AuthorizeUserRequestToJSONTyped(json, false);
}

export function AuthorizeUserRequestToJSONTyped(
  value?: AuthorizeUserRequest | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    houseId: value['houseId'],
    userAuthorizedEmail: value['userAuthorizedEmail'],
  };
}

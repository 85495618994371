import {
  ClevergyEventHandler,
  ClevergyEventType,
} from '../classes/EventBus.class';
import { ClevergyEventPayloads } from '../constants/clevergyEventTypes';
import { useEventBusContext } from '../context/EventBusContext';
import { useEffect } from 'react';

export const useClevergyEvent = <T extends ClevergyEventType>(
  type: T,
  callback?: ClevergyEventHandler<T>,
) => {
  const { eventBus } = useEventBusContext();

  useEffect(() => {
    if (!callback) {
      return;
    }
    const unsubscribe = eventBus.subscribeToEvent(type, callback);
    return unsubscribe;
  }, [callback, eventBus, type]);

  return (
    data: T extends keyof ClevergyEventPayloads
      ? ClevergyEventPayloads[T]
      : null,
  ) => eventBus.dispatchEvent({ type, data });
};

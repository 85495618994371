import { TenantSettings } from '@clevergy/api-client-portal-routes';
import { FC, ReactNode, createContext, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const TenantSettingsContext = createContext<TenantSettings | null>(null);

export type TenantSettingsProviderProps = {
  tenantSettings: TenantSettings;
  children: ReactNode;
};

export const TenantSettingsProvider: FC<TenantSettingsProviderProps> = ({
  tenantSettings,
  children,
}) => {
  const { i18n } = useTranslation();

  if (!tenantSettings) {
    throw new Error(`Tenant settings not found`);
  }

  const { tenantId } = tenantSettings;

  // Set the tenant translation namespace to use the tenant translations
  // If namespace is not found, uses the default namespace
  useEffect(() => {
    if (tenantId) {
      const tenantTranslationNamespace = `tenant_translations_${tenantId
        .toLowerCase()
        .replace(/-/g, '_')}`;
      i18n.setDefaultNamespace(tenantTranslationNamespace);
    }
  }, [i18n, tenantId, tenantSettings.tenantId]);

  return (
    <TenantSettingsContext.Provider value={tenantSettings}>
      {children}
    </TenantSettingsContext.Provider>
  );
};

export const useTenantSettings = () => {
  const setup = useContext(TenantSettingsContext);
  if (!setup) {
    throw new Error(
      `useTenantSettings must be used within a TenantSettingsProvider`,
    );
  }
  return setup;
};

import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { PrivacyPolicy } from 'components/PrivacyPolicy';
import { Wrapper } from 'components/Wrapper';
import { useTranslation } from 'react-i18next';

export const PrivacypolicyPage = () => {
  const { t } = useTranslation();
  return (
    <Page>
      <PageHeader
        backButton
        backButtonPath="/"
        title={t('legal.privacyPolicy.title') as string}
      />
      <Wrapper>
        <PrivacyPolicy />
      </Wrapper>
    </Page>
  );
};

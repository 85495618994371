import clsx from 'clsx';
import { FC, ReactNode } from 'react';

export type PageProps = {
  navbarLayoutContentMobileBackground?: string;
  children: ReactNode;
};

export const Page: FC<PageProps> = ({
  children,
  navbarLayoutContentMobileBackground = 'transparent',
}) => {
  return (
    <>
      <style>
        {/* Define the background color of the navbar layout content for mobile */}
        {` #navbar-layout {
          --navbar-layout-content-mobile-background: ${navbarLayoutContentMobileBackground};
        }`}
      </style>
      <div
        className={clsx('flex flex-col h-full min-h-full', {
          'bg-white sm:m-4 sm:bg-clevergy-moduleContainerBackground sm:shadow-moduleContainer sm:rounded-moduleContainer overflow-clip':
            navbarLayoutContentMobileBackground !== 'transparent',
        })}
      >
        {children}
      </div>
    </>
  );
};

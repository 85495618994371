import {
  FirebaseAuthentication,
  Persistence,
  User,
} from '@capacitor-firebase/authentication';
import { Capacitor } from '@capacitor/core';
import { isClevergyEmail } from '@clevergy/utils/validations/isClevergyEmail';
import { useAnalyticsContext } from 'context/AnalyticsContext';
import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

const AUTH_RESOLVED_TIMEOUT = 4000;

export type AuthContextValue = {
  /**
   * Whether the tenant settings has been resolved or not
   */
  hasResolvedTenantId: boolean;
  /**
   * The JWT token of the current user
   */
  token: string | null | undefined;
  /**
   * The current user
   */
  authedUser: User | null | undefined;
  /**
   * Set the GCP tenant id for Firebase Authentication
   * @param tenantGcpId
   * @returns void
   */
  setAuthTenantGcpId: (tenantGcpId: string) => Promise<void>;
  /**
   * Enable or disable the email verification pulling
   */
  setIsEnabledEmailVerificationPulling: (isEnabled: boolean) => void;
  /**
   * Refresh the token
   */
  refreshToken: () => Promise<string>;
  /**
   * Sign out the current user
   */
  signOut: () => Promise<void>;
};

export const AuthContext = createContext<AuthContextValue | null>(null);

export const AuthProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { identify } = useAnalyticsContext();
  const authedUserResolvedTimeoutId = useRef<NodeJS.Timeout | null>(null);

  const [hasResolvedTenantId, setHasResolvedTenantId] = useState(false);
  const [
    isEnabledEmailVerificationPulling,
    setIsEnabledEmailVerificationPulling,
  ] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [authedUser, setAuthedUser] = useState<User | null | undefined>(
    undefined,
  );

  // Workaround here instead of at AuthService for refreshing and persisting the token
  // since FirebaseAuthentication.getIdToken() doesn't fires the onAuthStateChanged event
  const refreshToken = useCallback(async () => {
    const { token } = await FirebaseAuthentication.getIdToken({
      forceRefresh: true,
    });
    setToken(token);
    return token;
  }, []);

  // Provide a way to set the tenant id in the auth context
  const setAuthTenantGcpId = useCallback(async (tenantGcpId: string) => {
    await FirebaseAuthentication.setLanguageCode({ languageCode: 'es-ES' });
    console.log('Using Auth Language: ', 'es-ES');
    await FirebaseAuthentication.setTenantId({ tenantId: tenantGcpId });
    setHasResolvedTenantId(true);
    console.log('Using GCP Tenant: ', tenantGcpId);
  }, []);

  // Start timeout to resolve auth user
  useEffect(() => {
    if (
      authedUser ||
      !hasResolvedTenantId ||
      authedUserResolvedTimeoutId.current
    ) {
      return;
    }
    // Timeout to resolve auth user
    authedUserResolvedTimeoutId.current = setTimeout(() => {
      console.log('Auth user timeout...');
      // // Get current user if any (in case onAuthStateChanged runs late and we miss the first event)
      console.log('Getting current user');
      FirebaseAuthentication.getCurrentUser().then(({ user }) => {
        if (user) {
          FirebaseAuthentication.getIdToken().then(({ token: newToken }) => {
            setToken(newToken);
            setAuthedUser(user);
          });
        } else {
          setAuthedUser(null);
        }
      });
    }, AUTH_RESOLVED_TIMEOUT);

    return () => {
      if (authedUserResolvedTimeoutId.current) {
        clearTimeout(authedUserResolvedTimeoutId.current);
      }
    };
  }, [authedUser, hasResolvedTenantId]);

  // Subscribe to auth state changes and check if user is already logged in because we
  // only get notified when the user logs in or out after we subscribe to the event
  useEffect(() => {
    // Only set persistence for web (non native platforms will use the default persistence method)
    if (!Capacitor.isNativePlatform()) {
      FirebaseAuthentication.setPersistence({
        persistence: Persistence.BrowserLocal,
      });
    }
    // Subscribe to auth state changes
    console.log('Subscribing to auth state changes');
    FirebaseAuthentication.addListener('authStateChange', (data) => {
      const { user } = data;
      if (user) {
        FirebaseAuthentication.getIdToken().then(({ token }) => {
          console.log('Auth state changed: ', { token: token, user });
          setAuthedUser(user);
          setToken(token);
        });
      } else {
        setAuthedUser(null);
        setToken(null);
        console.log('Auth state changed: Logout');
      }
    });
    return () => {
      FirebaseAuthentication.removeAllListeners();
    };
  }, []);

  // Identify user in Analytics
  useEffect(() => {
    const isClevergyMember = isClevergyEmail(authedUser?.email);
    identify({
      userId: authedUser?.uid,
      tenantId: authedUser?.tenantId,
      isClevergyMember,
    });
  }, [authedUser, identify]);

  // Compose context value
  const value: AuthContextValue = useMemo(
    () => ({
      hasResolvedTenantId,
      authedUser,
      token,
      setAuthTenantGcpId,
      setIsEnabledEmailVerificationPulling,
      refreshToken,
      signOut: async () => FirebaseAuthentication.signOut(),
    }),
    [
      hasResolvedTenantId,
      authedUser,
      token,
      setAuthTenantGcpId,
      setIsEnabledEmailVerificationPulling,
      refreshToken,
    ],
  );

  // Reload info about user every 10 seconds when emailVerified is false
  useEffect(() => {
    const reloadUserInterval: NodeJS.Timeout = setInterval(() => {
      if (
        !isEnabledEmailVerificationPulling ||
        !value.authedUser ||
        value.authedUser?.emailVerified
      ) {
        return clearInterval(reloadUserInterval);
      }
      console.log('Reloading user info...');

      FirebaseAuthentication.reload().then(() => {
        FirebaseAuthentication.getCurrentUser().then((result) => {
          if (result.user) {
            setAuthedUser(result.user);
          }
        });
      });
    }, 10 * 1000);
    return () => clearInterval(reloadUserInterval);
  }, [isEnabledEmailVerificationPulling, value.authedUser]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw Error('useAuthContext must be used inside of a AuthContextProvider');
  }
  return context;
};

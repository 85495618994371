import { es, Locale } from 'date-fns/locale';
import i18n from 'i18next';
import es_ES from 'locales/es-ES';
import { initReactI18next } from 'react-i18next';

const DEFAULT_LANGUAGE = 'es-ES';

// Prepare resources for i18next
const resources = {
  'es-ES': es_ES,
};

// Calculate available languages
const availableLanguages = Object.keys(resources);
type AvailableLanguages = (typeof availableLanguages)[number];

// Locales to use with date-fns for each language
const dateFnsLocales: Record<AvailableLanguages, Locale> = {
  'es-ES': es,
};

// Initialize i18next
i18n.use(initReactI18next).init({
  debug: process.env.NODE_ENV === 'development',
  lng: DEFAULT_LANGUAGE,
  fallbackLng: DEFAULT_LANGUAGE,
  defaultNS: 'translation',
  fallbackNS: 'translation',
  resources,
});

export {
  i18n,
  resources,
  availableLanguages,
  type AvailableLanguages,
  DEFAULT_LANGUAGE,
  dateFnsLocales,
};

import { CreateHouseContractBanner } from './CreateHouseContractBanner';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { Alert } from '@clevergy/ui/components/Alert';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { skipToken, useQuery } from '@tanstack/react-query';
import { useApiContext } from 'context/ApiContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { useTranslation } from 'react-i18next';

export const HouseContractAlerts = () => {
  const { api } = useApiContext();
  const { selectedHouse } = useSelectedHouse();
  const { t } = useTranslation();

  const houseContractQuery = useQuery({
    queryKey: [QueryKeys.HOUSE_CONTRACT, { houseId: selectedHouse?.houseId }],
    queryFn: selectedHouse?.houseId
      ? () =>
          api.contracts.getHouseLastContract({
            houseId: selectedHouse?.houseId,
          })
      : skipToken,
    placeholderData: (previousData) => previousData,
  });

  if (
    houseContractQuery.data?.status !== 'SUBMITTED' &&
    houseContractQuery.data?.status !== 'Active'
  ) {
    return <CreateHouseContractBanner />;
  }

  if (houseContractQuery.data?.status === 'SUBMITTED') {
    return (
      <div className="mb-4">
        <Alert
          type="primary"
          icon={<FontIcon name="hourglass" variant="outlined" />}
          title={t('contract.inProgress.title') as string}
          text={t('contract.inProgress.text') as string}
        />
      </div>
    );
  }

  return null;
};

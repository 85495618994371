import { Button } from '@clevergy/ui/components/Button';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { ModuleCard } from '@clevergy/ui/components/ModuleCard';
import { useSmartMeterIntegration } from 'hooks/useSmartMeterIntegration';
import { Link } from 'react-router-dom';

export const SmartMeterBanner = () => {
  const {
    shouldSuggestSmartMeterIntegration,
    isSmartMeterIntegrationInProgress,
  } = useSmartMeterIntegration();

  if (shouldSuggestSmartMeterIntegration || isSmartMeterIntegrationInProgress) {
    return (
      <ModuleCard>
        {isSmartMeterIntegrationInProgress ? (
          <ModuleCard.Header
            title="¡Tus consumos están de camino!"
            subtitle="Estamos conectándonos a tus consumos, no es un proceso inmediato. Pronto podrás..."
          />
        ) : (
          <ModuleCard.Header title="Disfruta cuanto antes de estas funcionalidades..." />
        )}
        <ModuleCard.Content>
          <div className="flex flex-col gap-4 py-2">
            <div className="flex gap-2 items-center">
              <div className="text-clevergy-primary">
                <FontIcon name="history" />
              </div>
              Acceder y comparar tus consumos a lo largo del tiempo
            </div>
            <div className="flex gap-2 items-center">
              <div className="text-clevergy-primary">
                <FontIcon name="home_work" />
              </div>
              Comparar tu consumo con el de tus vecinos
            </div>
            <div className="flex gap-2 items-center">
              <div className="text-clevergy-primary">
                <FontIcon name="kitchen" />
              </div>
              Averiguar qué electrodomésticos consumen más
            </div>
          </div>
          {!isSmartMeterIntegrationInProgress && (
            <div className="flex flex-col gap-2">
              <Link to="/integrations/datadis">
                <Button variant="filled" fullWidth>
                  Acceder a mis consumos
                </Button>
              </Link>
              <p className="text-clevergy-subtext text-sm">
                Necesitaremos que subas una foto del DNI y que indiques el CUPS
                de tu casa.
              </p>
            </div>
          )}
        </ModuleCard.Content>
      </ModuleCard>
    );
  }
  return null;
};

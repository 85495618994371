/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface TenantUserIntegration
 */
export interface TenantUserIntegration {
  /**
   *
   * @type {string}
   * @memberof TenantUserIntegration
   */
  integration?: TenantUserIntegrationIntegrationEnum;
  /**
   *
   * @type {boolean}
   * @memberof TenantUserIntegration
   */
  userConnected?: boolean;
}

/**
 * @export
 */
export const TenantUserIntegrationIntegrationEnum = {
  Alexa: 'ALEXA',
  Datadis: 'DATADIS',
  HuaweiOauth: 'HUAWEI_OAUTH',
  Shelly: 'SHELLY',
} as const;
export type TenantUserIntegrationIntegrationEnum =
  (typeof TenantUserIntegrationIntegrationEnum)[keyof typeof TenantUserIntegrationIntegrationEnum];

/**
 * Check if a given object implements the TenantUserIntegration interface.
 */
export function instanceOfTenantUserIntegration(
  value: object,
): value is TenantUserIntegration {
  return true;
}

export function TenantUserIntegrationFromJSON(
  json: any,
): TenantUserIntegration {
  return TenantUserIntegrationFromJSONTyped(json, false);
}

export function TenantUserIntegrationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TenantUserIntegration {
  if (json == null) {
    return json;
  }
  return {
    integration: json['integration'] == null ? undefined : json['integration'],
    userConnected:
      json['userConnected'] == null ? undefined : json['userConnected'],
  };
}

export function TenantUserIntegrationToJSON(json: any): TenantUserIntegration {
  return TenantUserIntegrationToJSONTyped(json, false);
}

export function TenantUserIntegrationToJSONTyped(
  value?: TenantUserIntegration | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    integration: value['integration'],
    userConnected: value['userConnected'],
  };
}

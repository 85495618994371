import { Button } from '../Button';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';

export type CardProps = {
  icon?: ReactNode;
  title: string;
  subtitle?: string;
  children: ReactNode;
  headerEndContent?: ReactNode;
  withShadow?: boolean;
  isSelected?: boolean;
  actionButton?: {
    label: string;
    onClick: () => void;
  };
};

export const Card: FC<CardProps> = (props) => {
  const {
    icon,
    title,
    subtitle,
    children,
    headerEndContent,
    actionButton,
    withShadow,
    isSelected,
  } = props;

  return (
    <div
      className={clsx(
        'flex flex-col border-clevergy-border rounded-lg border p-4 gap-4',
        {
          'shadow-md': withShadow,
          'border-clevergy-primary': isSelected,
        },
      )}
    >
      <div className="flex flex-col gap-1">
        <div className="flex gap-2 w-full">
          {icon && (
            <div className="flex-shrink-0 text-clevergy-primary">{icon}</div>
          )}
          <h4 className="text-xl grow font-semibold leading-6">{title}</h4>
          {headerEndContent}
        </div>
        {subtitle && <p className="text-sm text-zinc-500">{subtitle}</p>}
      </div>
      <div>{children}</div>

      {actionButton && (
        <Button fullWidth colorScheme="primary" onClick={actionButton.onClick}>
          {actionButton.label}
        </Button>
      )}
    </div>
  );
};

import { FontIcon, FontIconProps } from '../../FontIcon';
import Alexa from './clevergy/icon-alexa.svg?react';
import Clevergy from './clevergy/icon-clevergy.svg?react';
import Datadis from './clevergy/icon-datadis.svg?react';
import DeviceElectricBoiler from './clevergy/icon-device-electric-boiler.svg?react';
import DeviceElectricWaterHeater from './clevergy/icon-device-electric-water-heater.svg?react';
import DeviceGarageDoor from './clevergy/icon-device-garage-door.svg?react';
import DeviceGasWaterHeater from './clevergy/icon-device-gas-water-heater.svg?react';
import DeviceHeater from './clevergy/icon-device-heater.svg?react';
import DeviceHotWater from './clevergy/icon-device-hot-water.svg?react';
import DeviceMicrowave from './clevergy/icon-device-microwave.svg?react';
import DeviceRadiator from './clevergy/icon-device-radiator.svg?react';
import DeviceSwitchboard from './clevergy/icon-device-switchboard.svg?react';
import DeviceVacuum from './clevergy/icon-device-vacuum.svg?react';
import HouseEfficient from './clevergy/icon-house-efficient.svg?react';
import HouseVerified from './clevergy/icon-house-verified.svg?react';
import Huawei from './clevergy/icon-huawei.svg?react';
import Network from './clevergy/icon-network.svg?react';
import Shelly from './clevergy/icon-shelly.svg?react';
import SmartPlug from './clevergy/icon-smart-plug.svg?react';
import SocialApple from './clevergy/icon-social-apple.svg?react';
import SocialGoogle from './clevergy/icon-social-google.svg?react';
import SolarEdge from './clevergy/icon-solar-edge.svg?react';
import Whatsapp from './clevergy/icon-whatsapp.svg?react';

function fontIcon(name: string, variant?: 'filled' | 'outlined') {
  const IconComponent = (props: Omit<FontIconProps, 'name'>) => (
    <FontIcon {...props} variant={variant} name={name} />
  );
  IconComponent.displayName = `FontIcon(${name})`;
  return IconComponent;
}

export const ClevergyIcons = {
  Alexa,
  Clevergy,
  Calendar: fontIcon('calendar_month'),
  Datadis,
  DeviceAc: fontIcon('mode_fan'),
  DeviceAerothermal: fontIcon('airwave'),
  DeviceBattery: fontIcon('battery_charging_50'),
  DeviceCar: fontIcon('directions_car'),
  DeviceCeramic: fontIcon('skillet'),
  DeviceChargingPoint: fontIcon('electric_car'),
  DeviceComputer: fontIcon('desktop_windows', 'outlined'),
  DeviceDishwasher: fontIcon('dishwasher_gen'),
  DeviceDryer: fontIcon('local_laundry_service'),
  DeviceElectricBoiler,
  DeviceElectricWaterHeater,
  DeviceFlame: fontIcon('local_fire_department'),
  DeviceFreezer: fontIcon('ac_unit'),
  DeviceFridge: fontIcon('kitchen'),
  DeviceGarageDoor,
  DeviceGasBoiler: fontIcon('water_heater'),
  DeviceGasWaterHeater,
  DeviceGeothermal: fontIcon('heat'),
  DeviceHeater,
  DeviceHotWater,
  DeviceMicrowave,
  DeviceLight: fontIcon('floor_lamp'),
  DeviceOther: fontIcon('deployed_code', 'outlined'),
  DeviceOven: fontIcon('oven'),
  DevicePhotovoltaicPanel: fontIcon('solar_power'),
  DevicePurifier: fontIcon('sync'),
  DeviceRadiator,
  DeviceSolarPanel: fontIcon('solar_power'),
  DeviceStandby: fontIcon('power_settings_new'),
  DeviceTv: fontIcon('tv_gen', 'outlined'),
  DeviceSwitchboard,
  DeviceVacuum,
  DeviceWashingMachine: fontIcon('local_laundry_service'),
  Flat: fontIcon('apartment'),
  House: fontIcon('home'),
  HouseEfficient,
  HouseVerified,
  Huawei,
  Location: fontIcon('location_on'),
  Monitoring: fontIcon('monitoring'),
  Neighbors: fontIcon('location_city'),
  Network,
  PeopleOne: fontIcon('person'),
  PeopleTwo: fontIcon('group'),
  PeopleThree: fontIcon('groups'),
  PeopleMore: fontIcon('group_add'),
  Shelly,
  SocialApple,
  SocialGoogle,
  SolarEdge,
  SmartPlug,
  SwitchOff: fontIcon('power_settings_new'),
  SwitchOn: fontIcon('power_settings_new'),
  Whatsapp,
};

import { useApiContext } from './ApiContext';
import { useAuthContext } from './AuthContext';
import {
  IntegrationsStatus,
  UserSetUpHouse,
  UserSetUpUserStatusEnum,
} from '@clevergy/api-client';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { validateCIF } from '@clevergy/utils/validations/validateCIF';
import { useQuery } from '@tanstack/react-query';
import { useAnalyticsContext } from 'context/AnalyticsContext';
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

const ONE_MINUTE = 1000 * 60 * 1;
const FIVE_SECONDS = 1000 * 5;

type AlexaAuthorization = {
  state: string;
  redirect_uri: string;
};

export type UserSetupContextValue = {
  userStatus: UserSetUpUserStatusEnum | undefined;
  houses: UserSetUpHouse[] | undefined;
  refetchSetup: () => Promise<void>;
  integrationsStatus: IntegrationsStatus | undefined;
  alexaAuthorization: AlexaAuthorization | null;
  setAlexaAuthorization: (alexaAuthorization: AlexaAuthorization) => void;
  hasIdentityDocumentValidated: boolean;
  userInfo: {
    name?: string;
    surname?: string;
    email?: string;
    dni?: string;
    isCompany?: boolean;
  };
};

export const UserSetupContext = createContext<UserSetupContextValue | null>(
  null,
);

export const UserSetupProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { api } = useApiContext();
  const { authedUser } = useAuthContext();
  const { identify } = useAnalyticsContext();

  const userSetupQuery = useQuery({
    queryKey: [QueryKeys.GET_USER_SETUP, { userId: authedUser?.uid }],
    queryFn: () => {
      return api.users.retrieveUserSetUp({ userId: authedUser?.uid || '' });
    },
    refetchInterval: (query) => {
      if (
        query.state.data?.userStatus ===
        UserSetUpUserStatusEnum.AcceptedConditions
      ) {
        return FIVE_SECONDS;
      }
      return ONE_MINUTE;
    },
    enabled: Boolean(authedUser?.uid),
    gcTime: 0,
    staleTime: 0,
  });

  const userAccountInfoQuery = useQuery({
    queryKey: [
      QueryKeys.GET_USER_ACCOUNT_DETAILS,
      {
        userId: authedUser?.uid || 'me',
      },
    ],
    queryFn: () =>
      api.users.getUserAccountDetails({
        userId: authedUser?.uid || 'me',
      }),
    refetchInterval: () => {
      if (
        userSetupQuery.data?.userStatus ===
        UserSetUpUserStatusEnum.AcceptedConditions
      ) {
        return FIVE_SECONDS;
      }
      return ONE_MINUTE;
    },
    enabled: Boolean(authedUser?.uid),
    gcTime: 0,
    staleTime: 0,
  });

  // Integrations - Alexa
  const [alexaAuthorization, setAlexaAuthorization] =
    useState<AlexaAuthorization | null>(null);

  const userInfo = useMemo(() => {
    return {
      name: userAccountInfoQuery.data?.name,
      surname: userAccountInfoQuery.data?.surname,
      email: userAccountInfoQuery.data?.email,
      dni: userAccountInfoQuery.data?.dni,
      phoneNumber: userAccountInfoQuery.data?.phoneNumber,
      isCompany: userAccountInfoQuery.data?.dni
        ? validateCIF(userAccountInfoQuery.data?.dni)
        : false,
    };
  }, [userAccountInfoQuery.data]);

  // Analytics (Amplitude) for user setup
  useEffect(() => {
    identify({
      userStatus: userSetupQuery.data?.userStatus,
      hasAnyHouseSolar: userSetupQuery.data?.houses?.some(
        (h) => h.dataProviders?.solarInverter,
      ),
    });
  }, [
    authedUser,
    identify,
    userSetupQuery.data?.houses,
    userSetupQuery.data?.userStatus,
  ]);

  const value: UserSetupContextValue = useMemo(
    () => ({
      userStatus: userSetupQuery.data?.userStatus,
      houses: userSetupQuery.data?.houses,
      refetchSetup: async () => {
        userSetupQuery.refetch();
      },
      alexaAuthorization,
      setAlexaAuthorization,
      integrationsStatus: userSetupQuery.data?.integrationsStatus,
      hasIdentityDocumentValidated:
        userSetupQuery.data?.hasIdentityDocumentValidated || false,
      userInfo,
    }),
    [userSetupQuery, alexaAuthorization, userInfo],
  );

  return (
    <UserSetupContext.Provider value={value}>
      {children}
    </UserSetupContext.Provider>
  );
};

export const useUserSetupContext = () => {
  const context = useContext(UserSetupContext);
  if (context === null) {
    throw Error(
      'useUserSetupContext must be used inside of a UserSetupContext',
    );
  }
  return context;
};

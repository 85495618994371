import { Modal } from '@clevergy/ui/components/Modal';
import { Prose } from '@clevergy/ui/components/Prose';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  onCloseModal: () => void;
}

export const MoreInformationModal: FC<Props> = ({
  isOpen,
  onCloseModal,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={() => onCloseModal()}>
      <Prose>
        <div className="flex flex-col gap-2">
          <h2>{t('smartDevices.moreInformation.title')}</h2>
          <p>{t('smartDevices.moreInformation.subtitle')}</p>
          <ul className="space-y-4">
            <li>
              <Trans i18nKey="smartDevices.moreInformation.list.item1" />
            </li>
            <li>
              <Trans i18nKey="smartDevices.moreInformation.list.item2" />
            </li>
            <li>
              <Trans i18nKey="smartDevices.moreInformation.list.item3" />
            </li>
          </ul>
        </div>
      </Prose>
    </Modal>
  );
};

import { FontIcon } from '@clevergy/ui/components/FontIcon';
import clsx from 'clsx';
import { HousePicker } from 'components/HousePicker';
import { TenantLogo } from 'components/TenantLogo';
import { AnimatePresence, motion } from 'framer-motion';
import { NavbarItemDescription, useNavbarMenu } from 'hooks/useNavbarMenu';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Link, matchRoutes, useLocation } from 'react-router-dom';

export const NonMobileNavbar = () => {
  const { navbarMenuItems } = useNavbarMenu();

  return (
    <div className="hidden w-[260px] shrink-0 overflow-auto bg-white sm:block xl:fixed xl:inset-y-0 xl:left-0 z-50">
      <div className="flex flex-col h-full w-full">
        <div className="px-4 pt-6 pb-8">
          <HousePicker />
        </div>
        <div className="flex flex-col gap-1">
          {navbarMenuItems.map((item) => (
            <NavbarItem key={item.label} itemDescription={item} />
          ))}
        </div>
        <div className="grow"></div>
        <div className="px-4 py-4">
          <TenantLogo type="default" width={180} height={90} />
        </div>
      </div>
    </div>
  );
};

const NavbarItem = (props: { itemDescription: NavbarItemDescription }) => {
  const { itemDescription } = props;

  if (
    itemDescription.childrenItems &&
    itemDescription.childrenItems.length > 0
  ) {
    return (
      <NavbarCollapsableGroup
        label={itemDescription.label}
        icon={itemDescription.icon}
        childrenItems={itemDescription.childrenItems}
      />
    );
  }

  if (itemDescription.to && itemDescription.renderCondition) {
    return (
      <NavbarItemLink
        label={itemDescription.label}
        icon={itemDescription.icon}
        to={itemDescription.to}
        badge={itemDescription.badge}
        matchingRoutes={itemDescription.matchingRoutes || []}
      />
    );
  }

  return null;
};

export const NavbarCollapsableGroup = (props: {
  icon: ReactNode;
  label: string;
  childrenItems: NavbarItemDescription[];
}) => {
  const { icon, label, childrenItems } = props;

  const location = useLocation();
  const userHasToggled = useRef(false);
  const [isOpen, setIsOpen] = useState(false);

  const matchingRoutes = childrenItems?.flatMap((i) => i.matchingRoutes || []);
  const isActive = matchRoutes(
    matchingRoutes.map((route) => ({ path: route })),
    location,
  );

  useEffect(() => {
    if (location) {
      userHasToggled.current = false;
    }
  }, [location]);

  useEffect(() => {
    if (isActive && !userHasToggled.current) {
      setIsOpen(true);
    }
  }, [isActive]);

  const handleToggle = () => {
    userHasToggled.current = true;
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button
        type="button"
        className={clsx(
          'flex items-center gap-1 px-4 py-3 w-full hover:bg-gray-100 transition-colors',
        )}
        onClick={handleToggle}
      >
        <div className="w-6">{icon}</div>
        <div className="flex gap-1">
          {label}
          <div className="w-6">
            <motion.div
              animate={{ rotate: isOpen ? -180 : 0 }}
              transition={{ duration: 0.3 }}
            >
              <FontIcon name="expand_more" />
            </motion.div>
          </div>
        </div>
      </button>
      <motion.div
        style={{ overflow: 'hidden' }}
        initial={{ height: 0, opacity: 1 }}
        animate={{
          height: isOpen ? 'auto' : 0,
          transition: { type: 'tween' },
        }}
        exit={{ height: 0, opacity: 1 }}
      >
        {childrenItems.map((itemDescription, index) => {
          return <NavbarItem key={index} itemDescription={itemDescription} />;
        })}
      </motion.div>
    </div>
  );
};

const NavbarItemLink = (props: {
  to: string;
  label: string;
  icon: ReactNode;
  badge?: number;
  matchingRoutes: string[];
}) => {
  const { to, label, icon, matchingRoutes, badge = 0 } = props;
  const location = useLocation();
  const isActive = matchRoutes(
    matchingRoutes.map((route) => ({ path: route })),
    location,
  );

  return (
    <AnimatePresence key={to} mode="popLayout">
      <Link to={to}>
        <div
          className={clsx(
            'flex items-center gap-1 px-4 py-3 w-full hover:bg-gray-100 transition-colors',
            {
              'text-clevergy-primary': isActive,
            },
          )}
        >
          <div className="w-6">{icon}</div>
          <div>{label}</div>
          {badge > 0 && (
            <motion.div
              className="flex items-center justify-center bg-clevergy-secondary text-white font-semibold rounded-full text-[10px] w-4 h-4 ml-1"
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ scale: 0 }}
            >
              {badge}
            </motion.div>
          )}
        </div>
      </Link>
    </AnimatePresence>
  );
};

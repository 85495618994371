import { PrivacyPolicy } from './PrivacyPolicy';
import { Modal } from '@clevergy/ui/components/Modal';
import { useState } from 'react';

export const LegalFooter = () => {
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);

  return (
    <>
      <p className="text-clevergy-subtext px-8 text-center text-xs">
        Al continuar, estás aceptando nuestra{' '}
        <button
          type="button"
          className="text-clevergy-primary"
          onClick={() => setShowPrivacyPolicyModal(true)}
        >
          Política de privacidad
        </button>
        .
      </p>
      <Modal
        isOpen={showPrivacyPolicyModal}
        onClose={() => setShowPrivacyPolicyModal(false)}
      >
        <PrivacyPolicy />
      </Modal>
    </>
  );
};

import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Alert } from '@clevergy/ui/components/Alert';
import { Button } from '@clevergy/ui/components/Button';
import { FormDivider } from '@clevergy/ui/components/FormDivider';
import { InputText } from '@clevergy/ui/components/InputText';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { isValidEmail } from '@clevergy/utils/validations/isValidEmail';
import { LegalFooter } from 'components/LegalFooter';
import { Page } from 'components/Page';
import { SocialLoginButtons } from 'components/SocialLoginButtons';
import { TenantPageIntroWithLogo } from 'components/TenantPageIntroWithLogo';
import { Wrapper } from 'components/Wrapper';
import { useTenantSettings } from 'context/TenantSettingsContext';
import { FirebaseError } from 'firebase/app';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

type SignupFormValues = {
  email: string;
  password: string;
  repeatPassword: string;
  customError?: string;
};

export const SignupPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSocialLoginInProgress, setIsSocialLoginInProgress] = useState(false);
  const { hasAppRegistrationEnabled, hasSocialLoginEnabled } =
    useTenantSettings();

  const {
    register,
    getValues,
    handleSubmit,
    setError,
    clearErrors,
    formState,
  } = useForm<SignupFormValues>();

  const onSubmit = async (data: SignupFormValues) => {
    try {
      await FirebaseAuthentication.createUserWithEmailAndPassword({
        email: data.email,
        password: data.password,
      });
      navigate('/login', { replace: true });
    } catch (error: unknown) {
      if (
        error instanceof FirebaseError &&
        error.code === 'auth/email-already-in-use'
      ) {
        setError('customError', {
          type: 'serverSideError',
          message: t('signUp.errors.existingAccount') as string,
        });
      } else {
        setError('customError', {
          type: 'serverSideError',
          message: t('signUp.errors.unknown') as string,
        });
      }
    }
  };

  // If sign up is disabled, redirect to login page
  if (!hasAppRegistrationEnabled) {
    navigate('/login', { replace: true });
    return null;
  }

  return (
    <Page>
      <Wrapper>
        {isSocialLoginInProgress && <LoadingIndicator fullScreen="overlay" />}

        <div className="flex flex-col gap-6 p-safe h-full">
          <div className="flex grow flex-col justify-center">
            <TenantPageIntroWithLogo
              title={t('signUp.title')}
              subtitle={t('signUp.subtitle') as string}
            />
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="">
            <div className="flex flex-col gap-4">
              <InputText
                id="email"
                type="email"
                label={t('signUp.email.label') as string}
                hiddenLabel
                autoComplete="email"
                placeholder={t('signUp.email.placeholder')}
                {...register('email', {
                  required: t('signUp.email.required') as string,
                  validate: (value) =>
                    isValidEmail(value) ||
                    (t('signUp.email.invalid') as string),
                })}
                error={formState.errors.email?.message as string}
              />

              <InputText
                id="password"
                type="password"
                label={t('signUp.password.label') as string}
                hiddenLabel
                showPasswordToggleButton
                autoComplete="new-password"
                placeholder={t('signUp.password.placeholder')}
                {...register('password', {
                  required: t('signUp.password.required') as string,
                  minLength: {
                    value: 6,
                    message: t('signUp.password.minLength') as string,
                  },
                })}
                error={formState.errors.password?.message as string}
              />

              <InputText
                id="repeatPassword"
                type="password"
                label={t('signUp.repeatPassword.label') as string}
                hiddenLabel
                showPasswordToggleButton
                autoComplete="new-password"
                placeholder={t('signUp.repeatPassword.placeholder')}
                {...register('repeatPassword', {
                  required: t('signUp.repeatPassword.required') as string,
                  validate: (value) =>
                    value === getValues('password') ||
                    (t('signUp.repeatPassword.invalid') as string),
                  minLength: {
                    value: 6,
                    message: t('signUp.repeatPassword.minLength') as string,
                  },
                  deps: ['password'],
                })}
                error={formState.errors.repeatPassword?.message as string}
              />

              {formState.errors.customError && (
                <Alert
                  type="error"
                  text={formState.errors.customError?.message as string}
                />
              )}

              <Button
                type="submit"
                variant="filled"
                fullWidth
                busy={formState.isSubmitting}
                onClick={() => clearErrors()}
              >
                {t('signUp.signUp')}
              </Button>
            </div>

            {hasSocialLoginEnabled && hasAppRegistrationEnabled && (
              <>
                <FormDivider text="O" />
                <div className="flex flex-col gap-4">
                  <SocialLoginButtons
                    onLoginStarted={() => setIsSocialLoginInProgress(true)}
                    onLoginFinished={() => setIsSocialLoginInProgress(false)}
                  />
                </div>
              </>
            )}

            <div className="text-clevergy-subtext my-6 space-y-2 text-center font-semibold">
              <p>
                {t('signUp.login.title')}{' '}
                <Link to="/login" className="text-clevergy-primary">
                  {t('signUp.login.link')}
                </Link>
              </p>
            </div>

            <LegalFooter />
          </form>
        </div>
      </Wrapper>
    </Page>
  );
};

import { Button } from '@clevergy/ui/components/Button';
import { ProcessIconSuccess } from '@clevergy/ui/components/ProcessIconSuccess';
import { Prose } from '@clevergy/ui/components/Prose';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export type ModuleSuccessProps = {
  title?: string;
  message?: string;
  button?: string;
  onClick?(): void;
};

export const ModuleSuccess: FC<ModuleSuccessProps> = (props) => {
  const { t } = useTranslation();
  const { title, message, button, onClick } = props;

  return (
    <div className="flex w-full flex-col items-center justify-center gap-2 text-center">
      <ProcessIconSuccess />
      <Prose>
        <h2>{title || t('common.success.module.title')}</h2>
        <p>{message || t('common.success.module.message')}</p>
      </Prose>
      {onClick && (
        <Button onClick={onClick}>
          {button || t('common.success.module.button')}
        </Button>
      )}
    </div>
  );
};

import { BaseEvent } from '@amplitude/analytics-types';
import { InvalidateQueryFilters } from '@tanstack/react-query';

export enum ClevergyEventTypes {
  /**
   * Event to track analytics events in the app from modules
   */
  TRACK_ANALYTICS_EVENT = 'TRACK_ANALYTICS_EVENT',
  /**
   * Event to invalidate all queries in the ReactQuery cache
   */
  INVALIDATE_ALL_QUERIES = 'INVALIDATE_ALL_QUERIES',
  /**
   * Event to invalidate a specific query in the ReactQuery cache
   */
  INVALIDATE_QUERY = 'INVALIDATE_QUERY',
  /**
   * Event emitted when house settings are saved
   */
  HOUSE_SETTINGS_SAVED = 'HOUSE_SETTINGS_SAVED',
  /**
   * Event emitted when house settings are not saved
   */
  HOUSE_SETTINGS_NOT_SAVED = 'HOUSE_SETTINGS_NOT_SAVED',
  /**
   * Event emitted when house settings are saved in backup (local storage)
   */
  HOUSE_SETTINGS_SAVED_IN_BACKUP = 'HOUSE_SETTINGS_SAVED_IN_BACKUP',
  /**
   * Event emitted by the <clevergy-data-consent-inline> module when the user turns to COMPLETED status
   * @deprecated
   */
  USER_STATUS_COMPLETED = 'USER_STATUS_COMPLETED',
  /**
   * Event emitted by the <clevergy-data-consent-inline> module when the user turns to INITIAL status
   * @deprecated
   */
  USER_STATUS_INITIALIZED = 'USER_STATUS_INITIALIZED',
  /**
   * Event emitted by the <clevergy-integration-smartmeter> module when is skipped
   */
  INTEGRATION_SMARTMETER_SKIPPED = 'INTEGRATION_SMARTMETER_SKIPPED',
  /**
   * Event emitted by the <clevergy-integration-smartmeter> module when is failed
   */
  INTEGRATION_SMARTMETER_FAILED = 'INTEGRATION_SMARTMETER_FAILED',
  /**
   * Event emitted by the <clevergy-integration-smartmeter> module request to open a url
   */
  INTEGRATION_SMARTMETER_REQUEST_URL_OPENING = 'INTEGRATION_SMARTMETER_REQUEST_URL_OPENING',
  /**
   * Event emitted by the <clevergy-integration-smartmeter> module when is completed
   */
  INTEGRATION_SMARTMETER_COMPLETED = 'INTEGRATION_SMARTMETER_COMPLETED',
  /**
   * Event emitted by the <clevergy-create-house-contract> module when is completed
   */
  CREATE_HOUSE_CONTRACT_COMPLETED = 'CREATE_HOUSE_CONTRACT_COMPLETED',
  /**
   * Event emitted by the <clevergy-create-house-contract> module when is skipped
   */
  CREATE_HOUSE_CONTRACT_SKIPPED = 'CREATE_HOUSE_CONTRACT_SKIPPED',
  /**
   * Event emitted by the <clevergy-data-consent> module when is skipped
   */
  DATA_CONSENT_SKIPPED = 'DATA_CONSENT_SKIPPED',
  /**
   * Event emitted by the <clevergy-data-consent> module when is failed
   */
  DATA_CONSENT_FAILED = 'DATA_CONSENT_FAILED',
  /**
   * Event emitted by the <clevergy-data-consent> module when is completed
   */
  DATA_CONSENT_COMPLETED = 'DATA_CONSENT_COMPLETED',
}

export type ClevergyEventPayloads = {
  [ClevergyEventTypes.INVALIDATE_QUERY]: InvalidateQueryFilters;
  [ClevergyEventTypes.TRACK_ANALYTICS_EVENT]: BaseEvent;
  [ClevergyEventTypes.HOUSE_SETTINGS_SAVED]: {
    cupsAvailable: boolean;
    houseSettingsFilled: boolean;
  };
  [ClevergyEventTypes.HOUSE_SETTINGS_NOT_SAVED]: {
    cupsAvailable: boolean;
    houseSettingsFilled: boolean;
  };
  [ClevergyEventTypes.HOUSE_SETTINGS_SAVED_IN_BACKUP]: {
    cupsAvailable: boolean;
    houseSettingsFilled: boolean;
  };
  [ClevergyEventTypes.INTEGRATION_SMARTMETER_REQUEST_URL_OPENING]: {
    url: string;
  };
  [ClevergyEventTypes.INTEGRATION_SMARTMETER_FAILED]: {
    errorCode: string;
  };
};

import { Alert } from '@clevergy/ui/components/Alert';
import { FontIcon } from '@clevergy/ui/components/FontIcon';

export const ComunidadSolarBanner = () => {
  return (
    <div className="mb-4">
      <Alert
        type="primary"
        icon={<FontIcon name="monitoring" />}
        title="¡Invierte en Comunidad Solar!"
        text={
          <>
            Por primera vez,
            <strong>abrimos el capital social</strong> a los comuneros que
            quieran ser <strong>inversores</strong>. Aprovecha la oportunidad.
          </>
        }
        actions={
          <a
            href="https://comunidadsolar.es/invierte-en-comunidad-solar/"
            className="text-clevergy-primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            ¡Infórmate aquí!
          </a>
        }
      />
    </div>
  );
};

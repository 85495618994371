import { Button, ButtonProps } from '@clevergy/ui/components/Button';
import { TrackedEvent, useAnalyticsContext } from 'context/AnalyticsContext';
import { FC } from 'react';

export type TrackedButtonProps = {
  event: TrackedEvent;
} & ButtonProps;

export const TrackedButton: FC<TrackedButtonProps> = (props) => {
  const { event, children, onClick } = props;
  const { track } = useAnalyticsContext();

  const trackedOnClick = () => {
    track(event);
    onClick && onClick();
  };

  return (
    <Button {...props} onClick={trackedOnClick}>
      {children}
    </Button>
  );
};

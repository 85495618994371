import { AppTutorialModal } from 'components/AppTutorialModal';
import { Outlet } from 'react-router';

export const AppTutorialRoute = () => {
  return (
    <>
      <AppTutorialModal />
      <Outlet />
    </>
  );
};

/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface HouseSetupDetails
 */
export interface HouseSetupDetails {
  /**
   *
   * @type {string}
   * @memberof HouseSetupDetails
   */
  houseId: string;
  /**
   *
   * @type {string}
   * @memberof HouseSetupDetails
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof HouseSetupDetails
   */
  cups?: string;
  /**
   *
   * @type {string}
   * @memberof HouseSetupDetails
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof HouseSetupDetails
   */
  postalCode?: string;
  /**
   *
   * @type {string}
   * @memberof HouseSetupDetails
   */
  timeZone?: string;
  /**
   *
   * @type {string}
   * @memberof HouseSetupDetails
   */
  addressBlockNumber?: string;
  /**
   *
   * @type {string}
   * @memberof HouseSetupDetails
   */
  addressFloor?: string;
  /**
   *
   * @type {string}
   * @memberof HouseSetupDetails
   */
  addressStaircase?: string;
  /**
   *
   * @type {string}
   * @memberof HouseSetupDetails
   */
  addressDoor?: string;
  /**
   *
   * @type {string}
   * @memberof HouseSetupDetails
   */
  addressAdditionalInfo?: string;
}

/**
 * Check if a given object implements the HouseSetupDetails interface.
 */
export function instanceOfHouseSetupDetails(
  value: object,
): value is HouseSetupDetails {
  if (!('houseId' in value) || value['houseId'] === undefined) return false;
  if (!('userId' in value) || value['userId'] === undefined) return false;
  return true;
}

export function HouseSetupDetailsFromJSON(json: any): HouseSetupDetails {
  return HouseSetupDetailsFromJSONTyped(json, false);
}

export function HouseSetupDetailsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): HouseSetupDetails {
  if (json == null) {
    return json;
  }
  return {
    houseId: json['houseId'],
    userId: json['userId'],
    cups: json['cups'] == null ? undefined : json['cups'],
    address: json['address'] == null ? undefined : json['address'],
    postalCode: json['postalCode'] == null ? undefined : json['postalCode'],
    timeZone: json['timeZone'] == null ? undefined : json['timeZone'],
    addressBlockNumber:
      json['addressBlockNumber'] == null
        ? undefined
        : json['addressBlockNumber'],
    addressFloor:
      json['addressFloor'] == null ? undefined : json['addressFloor'],
    addressStaircase:
      json['addressStaircase'] == null ? undefined : json['addressStaircase'],
    addressDoor: json['addressDoor'] == null ? undefined : json['addressDoor'],
    addressAdditionalInfo:
      json['addressAdditionalInfo'] == null
        ? undefined
        : json['addressAdditionalInfo'],
  };
}

export function HouseSetupDetailsToJSON(json: any): HouseSetupDetails {
  return HouseSetupDetailsToJSONTyped(json, false);
}

export function HouseSetupDetailsToJSONTyped(
  value?: HouseSetupDetails | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    houseId: value['houseId'],
    userId: value['userId'],
    cups: value['cups'],
    address: value['address'],
    postalCode: value['postalCode'],
    timeZone: value['timeZone'],
    addressBlockNumber: value['addressBlockNumber'],
    addressFloor: value['addressFloor'],
    addressStaircase: value['addressStaircase'],
    addressDoor: value['addressDoor'],
    addressAdditionalInfo: value['addressAdditionalInfo'],
  };
}

import { Card } from '@clevergy/ui/components/Card';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { Heading } from '@clevergy/ui/components/Heading';
import { Modal } from '@clevergy/ui/components/Modal';

export type ComunidadSolarModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ComunidadSolarModal = ({
  isOpen,
  onClose,
}: ComunidadSolarModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-3">
        <Heading as="h4" colorScheme="neutral" centered>
          ¡Hazte inversor y forma parte de la empresa!
        </Heading>

        <Card
          title="Invierte en Comunidad Solar"
          icon={<FontIcon name="volunteer_activism" />}
        >
          Por primera vez, abrimos nuestro capital social, brindándote la
          oportunidad de invertir en Comunidad Solar.
        </Card>

        <Card
          title="¿Por qué invertir?"
          icon={<FontIcon name="question_mark" />}
        >
          Esta es tu ocasión para no solo ahorrar con nuestras soluciones, sino
          también obtener beneficios económicos como inversor, impulsando un
          modelo energético más sostenible.
        </Card>

        <Card
          title="¿Cómo puedo hacerlo?"
          icon={<FontIcon name="monitoring" />}
        >
          Toda la información que necesitas ya está disponible en la{' '}
          <a
            href="https://comunidadsolar.es/invierte-en-comunidad-solar/"
            className="underline text-clevergy-primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            web
          </a>{' '}
          que hemos publicado específicamente para esta oportunidad. Podrás
          consultar todos los detalles y realizar tu inversión con total
          seguridad.
        </Card>
      </div>
    </Modal>
  );
};

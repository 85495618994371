import { Button } from '@clevergy/ui/components/Button';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { Heading } from '@clevergy/ui/components/Heading';
import { Modal } from '@clevergy/ui/components/Modal';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppConfigurationStore } from 'stores/appConfigurationStore';

export const AppTutorialModal: FC = () => {
  const { t } = useTranslation();
  const isOnboardingLessonsCompleted = useAppConfigurationStore(
    (state) => state.isOnboardingLessonsCompleted,
  );

  const setIsOnboardingLessonsAsCompleted = useAppConfigurationStore(
    (state) => state.setIsOnboardingLessonsAsCompleted,
  );

  return (
    <Modal isOpen={!isOnboardingLessonsCompleted}>
      <div className="flex h-full flex-col md:min-h-[640px] gap-4 items-center">
        <div className="py-10 px-6">
          <Heading as="h2" colorScheme="neutral" centered>
            {t('tutorial.title')}
          </Heading>
        </div>
        <div className="flex w-full flex-col gap-8 max-w-sm">
          {Object.entries(
            t('tutorial.bullets', {
              returnObjects: true,
            }),
          ).map(([key, value]) => (
            <div
              key={key}
              className="flex items-center justify-center font-semibold gap-2"
            >
              <div className="text-clevergy-primary shrink-0">
                <FontIcon name={value.icon} size={32} />
              </div>
              <p className="grow">{value.title}</p>
            </div>
          ))}
        </div>

        <div className="grow"></div>

        <Button
          onClick={() => setIsOnboardingLessonsAsCompleted(true)}
          fullWidth
          testId="tutorial-continue-button"
        >
          {t('tutorial.continueButton')}
        </Button>
      </div>
    </Modal>
  );
};

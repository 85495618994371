import {
  ClevergyEventTypes,
  ClevergyEventPayloads,
} from '../constants/clevergyEventTypes';

export type ClevergyEventType = keyof typeof ClevergyEventTypes;

export type ClevergyEvent<T extends ClevergyEventType = ClevergyEventType> = {
  type: T;
  data: T extends keyof ClevergyEventPayloads ? ClevergyEventPayloads[T] : null;
};

type ClevergyMessage = {
  clevergy: {
    event: ClevergyEvent;
  };
};

export type ClevergyEventHandler<
  T extends ClevergyEventType = ClevergyEventType,
> = (event: ClevergyEvent<T>) => void;

/**
 * Event bus class
 * @example
 * const eventBus = new EventBus();
 * const unsubscribe = eventBus.subscribeToEvent('event-type', (event) => {
 *    console.log(event);
 * });
 * // unsubscribe from event
 * unsubscribe();
 */
export class EventBus {
  private _targetWindow: Window | null = null;
  private _subscribers: Map<ClevergyEventType, ClevergyEventHandler[]>;
  private _onMessage: (event: MessageEvent) => void;

  constructor(targetWindow: Window = window) {
    if (!targetWindow) {
      throw new Error('Target window is not defined');
    }
    this._targetWindow = targetWindow;
    this._subscribers = new Map<ClevergyEventType, ClevergyEventHandler[]>();
    this._onMessage = this._handleMessage.bind(this);

    // start listening to messages
    this._targetWindow.addEventListener('message', this._onMessage);
  }

  /**
   * Handle new messages from target window
   * @param event message event
   * @private
   * */
  private _handleMessage(event: MessageEvent) {
    const clevergyMessage = event.data as ClevergyMessage;

    if (!clevergyMessage || !clevergyMessage.clevergy) {
      return;
    }

    const clevergyEvent = clevergyMessage.clevergy.event;
    const subscribers = this._subscribers.get(clevergyEvent.type) || [];

    subscribers?.forEach((callback) => {
      callback(clevergyEvent);
    });
  }

  /**
   * Dispatch event
   * @param clevergyEvent event to dispatch
   * @example
   * dispatchEvent({
   *   type: 'event-type',
   *   data: {
   *   // event data
   *   },
   * });
   */
  public dispatchEvent<T extends ClevergyEventType>(
    clevergyEvent: ClevergyEvent<T>,
  ) {
    const message: ClevergyMessage = {
      clevergy: {
        event: clevergyEvent,
      },
    };
    this._targetWindow?.postMessage(message, '*');
  }

  /**
   * Subscribe to event
   * @param eventType event type
   * @param callback callback to call when event is dispatched
   * @returns unsubscribe function
   * @example
   * const unsubscribe = subscribeToEvent('event-type', (event) => {
   *  console.log(event);
   * });
   * // unsubscribe from event
   * unsubscribe();
   * */
  public subscribeToEvent<T extends ClevergyEventType>(
    eventType: T,
    callback: ClevergyEventHandler<T>,
  ) {
    const subscribers = this._subscribers.get(eventType) || [];
    const index = subscribers.indexOf(callback as ClevergyEventHandler);

    if (index === -1) {
      subscribers.push(callback as ClevergyEventHandler);
      this._subscribers.set(eventType, subscribers);
    }

    return () => this._unsubscribeFromEvent(eventType, callback);
  }

  /**
   * Unsubscribe from event
   * @param eventType event type
   * @param callback callback to unsubscribe
   */
  private _unsubscribeFromEvent<T extends ClevergyEventType>(
    eventType: T,
    callback: ClevergyEventHandler<T>,
  ) {
    const subscribers = this._subscribers.get(eventType) || [];
    const index = subscribers.indexOf(callback as ClevergyEventHandler);
    if (index !== -1) {
      subscribers.splice(index, 1);
    }
    this._subscribers.set(eventType, subscribers);
  }

  /**
   * Remove all subscribers and listeners
   */
  public destroy() {
    this._subscribers.clear();
    this._targetWindow?.removeEventListener('message', this._onMessage);
  }
}

import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { HouseContractAlerts } from 'components/HouseContractAlerts';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { useAuthContext } from 'context/AuthContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const ContractPage: FC = () => {
  const { t } = useTranslation();
  const { token } = useAuthContext();

  const { selectedHouse } = useSelectedHouse();

  return (
    <Page>
      <PageHeader
        backButton
        title={t('contract.title') as string}
        withHousePicker
        hideTitleAndBackButtonForNonMobile
      />

      <Wrapper>
        {(!token || !selectedHouse) && (
          <div className="fixed inset-0 flex items-center justify-center">
            <LoadingIndicator />
          </div>
        )}
        {token && selectedHouse && (
          <>
            <HouseContractAlerts />
            <div className="flex flex-col gap-4">
              <clevergy-house-contract
                data-token={token}
                data-house-id={selectedHouse.houseId}
                data-show-empty-state="true"
              />
            </div>
          </>
        )}
      </Wrapper>
    </Page>
  );
};

import { ComunidadSolarModal } from './components/ComunidadSolarModal';
import { SurplusRecommendations } from './components/SurplusRecommendations';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { ModuleCard } from '@clevergy/ui/components/ModuleCard';
import { AppAlerts } from 'components/AppAlerts';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { PullToRefresh } from 'components/PullToRefresh';
import { SmartMeterBanner } from 'components/SmartMeterBanner';
import { TenantLoadingIndicator } from 'components/TenantLoadingIndicator';
import { Wrapper } from 'components/Wrapper';
import { useAuthContext } from 'context/AuthContext';
import { useTenantSettings } from 'context/TenantSettingsContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC, useState } from 'react';

export const OptimizePage: FC = () => {
  const { token } = useAuthContext();
  const { selectedHouse } = useSelectedHouse();
  const { hasRateRecommenderEnabled, tenantId } = useTenantSettings();
  const [showComunidadSolarModal, setShowComunidadSolarModal] = useState(false);

  return (
    <Page>
      <PageHeader withHousePicker />
      <PullToRefresh />
      <Wrapper>
        {!token && <TenantLoadingIndicator />}
        {token && (
          <>
            {tenantId === 'comunidad-solar' && (
              <ModuleCard>
                <ModuleCard.Content>
                  <button
                    onClick={() => setShowComunidadSolarModal(true)}
                    className="flex w-full items-center gap-2"
                  >
                    <div className="flex-shrink-0 text-clevergy-primary">
                      <FontIcon name="monitoring" />
                    </div>
                    <div className="flex-grow font-semibold text-left">
                      ¡Conviértete en inversor de Comunidad Solar!
                    </div>
                    <div className="flex-shrink-0">
                      <FontIcon name="arrow_forward_ios" />
                    </div>
                  </button>
                </ModuleCard.Content>
              </ModuleCard>
            )}

            <AppAlerts />

            <SmartMeterBanner />

            {selectedHouse && (
              <clevergy-recommendations
                data-token={token}
                data-house-id={selectedHouse.houseId}
              />
            )}

            {selectedHouse && (
              <clevergy-solar-recommender
                data-token={token}
                data-house-id={selectedHouse.houseId}
              />
            )}

            {selectedHouse && <SurplusRecommendations />}

            <clevergy-tips-panel
              data-token={token}
              data-house-id={selectedHouse?.houseId}
            />

            {selectedHouse && hasRateRecommenderEnabled && (
              <clevergy-rate-recommender
                data-token={token}
                data-house-id={selectedHouse.houseId}
                data-variant={
                  tenantId === 'smartconsum' ? 'lead-capture' : 'default'
                }
              />
            )}

            <ComunidadSolarModal
              isOpen={showComunidadSolarModal}
              onClose={() => setShowComunidadSolarModal(false)}
            />
          </>
        )}
      </Wrapper>
    </Page>
  );
};

import { UserSetUpHouse } from '@clevergy/api-client';
import { useUserSetupContext } from 'context/UserSetupContext';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useHousePickerStore } from 'stores/housePickerStore';

export const useSelectedHouse = () => {
  const { houses } = useUserSetupContext();
  const { houseId: houseIdFromParams } = useParams<{ houseId: string }>();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedHouse, setSelectedHouse] = useState<
    UserSetUpHouse | undefined | null
  >(undefined);

  const { latestSelectedHouseId, setLatestSelectedHouseId } =
    useHousePickerStore((state) => ({
      setLatestSelectedHouseId: state.setSelectedHouseId,
      latestSelectedHouseId: state.selectedHouseId,
    }));

  // Resolve the selected house
  useEffect(() => {
    if (typeof houses === 'undefined') {
      setSelectedHouse(undefined);
      return;
    }

    // If there are no houses, return null
    if (houses && houses.length === 0) {
      setSelectedHouse(null);
      return;
    }
    // If houseId is in the url, use it and ignore the latest selected house id
    if (houseIdFromParams) {
      const houseFromParams = houses?.find(
        (h) => h.houseId === houseIdFromParams,
      );
      if (houseFromParams) {
        setLatestSelectedHouseId(houseFromParams.houseId);
        setSelectedHouse(houseFromParams);
      }
      return;
    }
    // If there is no houseId in the url params, use the latest selected house id
    const latestSelectedHouse = houses?.find(
      (h) => h.houseId === latestSelectedHouseId,
    );
    if (latestSelectedHouse) {
      setSelectedHouse(latestSelectedHouse);
      return;
    }

    // If there is no latest selected house id, use the first house in the list
    const firstHouse = houses?.[0];
    if (firstHouse) {
      setLatestSelectedHouseId(firstHouse.houseId);
      setSelectedHouse(firstHouse);
      return;
    }

    // else, set the selected house to null
    setSelectedHouse(null);
  }, [
    houseIdFromParams,
    houses,
    latestSelectedHouseId,
    setLatestSelectedHouseId,
    setSelectedHouse,
  ]);

  // Calculate the date limits for the selected house
  const selectedHouseDateLimits = useMemo(() => {
    const minStartTimestamp = Math.min(
      selectedHouse?.dataProviders?.energyCommunity?.firstDate?.getTime() ||
        Infinity,
      selectedHouse?.dataProviders?.solarInverter?.firstDate?.getTime() ||
        Infinity,
      selectedHouse?.dataProviders?.smartMeter?.firstDate?.getTime() ||
        Infinity,
    );

    const maxEndTimestamp = Math.max(
      selectedHouse?.dataProviders?.energyCommunity?.lastDate?.getTime() ||
        -Infinity,
      selectedHouse?.dataProviders?.solarInverter?.lastDate?.getTime() ||
        -Infinity,
      selectedHouse?.dataProviders?.smartMeter?.lastDate?.getTime() ||
        -Infinity,
    );

    const minStartDate = Number.isFinite(minStartTimestamp)
      ? new Date(minStartTimestamp)
      : undefined;
    const maxEndDate = Number.isFinite(maxEndTimestamp)
      ? new Date(maxEndTimestamp)
      : undefined;

    return {
      minStartDate,
      maxEndDate,
    };
  }, [selectedHouse]);

  // Navigate to the current url with the target houseId
  const setSelectedHouseHandler = (houseId: string) => {
    if (!selectedHouse) {
      return;
    }

    const hasCurrentSelectedHouseIdInUrl =
      selectedHouse?.houseId &&
      new RegExp(`/houses/${selectedHouse.houseId}`).test(location.pathname);

    if (hasCurrentSelectedHouseIdInUrl) {
      const newUrl = location.pathname.replace(selectedHouse.houseId, houseId);
      navigate(newUrl, { replace: true });
    } else {
      setLatestSelectedHouseId(houseId);
      setSelectedHouse(houses?.find((h) => h.houseId === houseId));
    }
  };

  return {
    selectedHouse,
    selectedHouseDateLimits,
    setSelectedHouse: setSelectedHouseHandler,
  };
};

import tenant_translations_niba from './tenant-translations-niba.json';
import translation from './translation.json';

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

export type Translations = {
  translation: typeof translation;
  [key: `tenant_${string}`]: DeepPartial<typeof translation>;
};

const translations: Translations = {
  translation,
  tenant_translations_niba,
};

export default translations;

/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface AuthorizeUserChangeStatusRequest
 */
export interface AuthorizeUserChangeStatusRequest {
  /**
   *
   * @type {string}
   * @memberof AuthorizeUserChangeStatusRequest
   */
  houseId: string;
  /**
   *
   * @type {string}
   * @memberof AuthorizeUserChangeStatusRequest
   */
  userAuthorizedEmail: string;
  /**
   *
   * @type {string}
   * @memberof AuthorizeUserChangeStatusRequest
   */
  status: AuthorizeUserChangeStatusRequestStatusEnum;
}

/**
 * @export
 */
export const AuthorizeUserChangeStatusRequestStatusEnum = {
  Authorized: 'AUTHORIZED',
  Revoked: 'REVOKED',
} as const;
export type AuthorizeUserChangeStatusRequestStatusEnum =
  (typeof AuthorizeUserChangeStatusRequestStatusEnum)[keyof typeof AuthorizeUserChangeStatusRequestStatusEnum];

/**
 * Check if a given object implements the AuthorizeUserChangeStatusRequest interface.
 */
export function instanceOfAuthorizeUserChangeStatusRequest(
  value: object,
): value is AuthorizeUserChangeStatusRequest {
  if (!('houseId' in value) || value['houseId'] === undefined) return false;
  if (
    !('userAuthorizedEmail' in value) ||
    value['userAuthorizedEmail'] === undefined
  )
    return false;
  if (!('status' in value) || value['status'] === undefined) return false;
  return true;
}

export function AuthorizeUserChangeStatusRequestFromJSON(
  json: any,
): AuthorizeUserChangeStatusRequest {
  return AuthorizeUserChangeStatusRequestFromJSONTyped(json, false);
}

export function AuthorizeUserChangeStatusRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AuthorizeUserChangeStatusRequest {
  if (json == null) {
    return json;
  }
  return {
    houseId: json['houseId'],
    userAuthorizedEmail: json['userAuthorizedEmail'],
    status: json['status'],
  };
}

export function AuthorizeUserChangeStatusRequestToJSON(
  json: any,
): AuthorizeUserChangeStatusRequest {
  return AuthorizeUserChangeStatusRequestToJSONTyped(json, false);
}

export function AuthorizeUserChangeStatusRequestToJSONTyped(
  value?: AuthorizeUserChangeStatusRequest | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    houseId: value['houseId'],
    userAuthorizedEmail: value['userAuthorizedEmail'],
    status: value['status'],
  };
}

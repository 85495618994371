import { UpdateHouseSettingsRequest } from '@clevergy/api-client';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { useHouseSettingsBackupStore } from '@clevergy/shared/stores/houseSettingsBackupStore';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAnalyticsContext } from 'context/AnalyticsContext';
import { useApiContext } from 'context/ApiContext';
import { useUserSetupContext } from 'context/UserSetupContext';
import { FC, useEffect } from 'react';

export const HouseSettingsBackupRestorer: FC = () => {
  const { api } = useApiContext();
  const queryClient = useQueryClient();
  const { track } = useAnalyticsContext();
  const { houses } = useUserSetupContext();

  const houseSettings = useHouseSettingsBackupStore(
    (state) => state.houseSettings,
  );
  const clearHouseSettings = useHouseSettingsBackupStore(
    (state) => state.reset,
  );

  const updateHouseSettingsMutation = useMutation({
    mutationFn: (payload: UpdateHouseSettingsRequest) =>
      api.settings.updateHouseSettings(payload),
  });

  useEffect(() => {
    if (
      !updateHouseSettingsMutation.isPending &&
      houseSettings &&
      houses &&
      houses.length > 0
    ) {
      console.log('Restoring house settings...');

      // mutate each house settings using promise.all and mutateAsync
      const mutations = houses.map((house) => {
        console.log(`Restoring house settings for house ${house.houseId}...`);
        return updateHouseSettingsMutation.mutateAsync({
          houseId: house.houseId,
          houseSettings: {
            ...houseSettings,
            houseId: house.houseId,
          },
        });
      });

      Promise.allSettled(mutations).then((results) => {
        results.forEach((result) => {
          if (result.status === 'rejected') {
            console.error('Failed to restore house settings!', result.reason);
          }
          if (result.status === 'fulfilled') {
            console.log('House settings restored!', result.value.houseId);
            queryClient.invalidateQueries({
              queryKey: [
                QueryKeys.GET_HOUSE_SETTINGS,
                { houseId: result.value.houseId },
              ],
            });
          }
        });

        track({
          event_type: 'house_settings_restored_from_backup',
        });

        clearHouseSettings();
      });
    }
  }, [
    clearHouseSettings,
    houseSettings,
    houses,
    queryClient,
    track,
    updateHouseSettingsMutation,
  ]);

  return null;
};

import { Button } from '@clevergy/ui/components/Button';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { List } from '@clevergy/ui/components/List';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { useIntegrations } from 'hooks/useIntegrations';
import { useOpenIntegrationUrl } from 'hooks/useOpenIntegrationUrl';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const IntegrationsPage: FC = () => {
  const { t } = useTranslation();

  const { isPending, integrationsList } = useIntegrations();
  const { openIntegrationUrl } = useOpenIntegrationUrl();

  return (
    <Page navbarLayoutContentMobileBackground="white">
      <PageHeader
        backButton
        backButtonPath="/profile"
        title={t('integrations.title') as string}
        withHousePicker
        hideTitleAndBackButtonForNonMobile
      />

      <Wrapper>
        {isPending && <LoadingIndicator fullScreen="transparent" />}
        {!isPending && (
          <List>
            {integrationsList.map((item, index) => {
              let rightIcon = <div className="h-10"></div>; // Default empty space

              if (item.isConnected && item.urlForDetails) {
                rightIcon = (
                  <Link to={item.urlForDetails.url}>
                    <div className="flex items-center justify-center py-2">
                      <FontIcon name="chevron_right" size={24} />
                    </div>
                  </Link>
                );
              }

              if (!item.isConnected && item.urlForExplanation) {
                rightIcon = (
                  <Button
                    onClick={() =>
                      openIntegrationUrl({
                        integrationUrl: item.urlForExplanation,
                      })
                    }
                  >
                    {t('integrations.connect')}
                  </Button>
                );
              }

              if (!item.isConnected && !item.urlForExplanation) {
                rightIcon = (
                  <Button
                    onClick={() =>
                      openIntegrationUrl({
                        integrationUrl: item.urlForConnect,
                      })
                    }
                  >
                    {t('integrations.connect')}
                  </Button>
                );
              }

              return (
                <List.Item
                  key={index}
                  leftIcon={item.icon}
                  rightIcon={rightIcon}
                >
                  {item.title}
                </List.Item>
              );
            })}
          </List>
        )}
      </Wrapper>
    </Page>
  );
};

import { useTenantSettings } from 'context/TenantSettingsContext';
import { useMemo } from 'react';
import { useAppConfigurationStore } from 'stores/appConfigurationStore';

export type UserCompletionsItem = {
  path: string;
  pending: boolean;
};

export type UserCompletionsWhileOnboardingState = {
  /**
   * Whether or not the state is resolving because of pending queries.
   */
  isResolving: boolean;
  /**
   * Whether or not there are pending user completeions.
   */
  hasPendingUserCompletions: () => boolean;
  /**
   * Returns the paths of the pending user completions.
   */
  getPendingUserCompletionsPaths: () => string[];
  /**
   * Returns the next pending user completion.
   * If there are no pending completions, returns undefined.
   */
  getNextPendingCompletion: () => UserCompletionsItem | undefined;
};

export const useUserCompletionsWhileOnboarding =
  (): UserCompletionsWhileOnboardingState => {
    const { tenantId } = useTenantSettings();
    const { hasSkippedCreateHouseContract } = useAppConfigurationStore();

    const steps = useMemo(() => {
      return {
        createHouseContract: {
          path: '/create-house-contract',
          pending: tenantId === 'niba' && !hasSkippedCreateHouseContract,
        },
      };
    }, [hasSkippedCreateHouseContract, tenantId]);

    const resultState: UserCompletionsWhileOnboardingState = {
      isResolving: false,
      hasPendingUserCompletions: () =>
        Object.values(steps).some((completion) => completion.pending),
      getPendingUserCompletionsPaths: () =>
        Object.values(steps).map((completion) => completion.path),
      getNextPendingCompletion: () =>
        Object.values(steps).find((completion) => completion.pending),
    };

    return resultState;
  };

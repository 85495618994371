import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { Heading } from '@clevergy/ui/components/Heading';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const InvoicesNoData: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-4 items-center my-4 text-center">
      <div className="text-clevergy-primary">
        <FontIcon
          size={56}
          name="contract"
          variant="filled"
          circleBackgroundClass="bg-clevergy-primary/10"
        />
      </div>
      <div>
        <Heading as="h3" centered colorScheme="neutral">
          {t('invoices.noData.title')}
        </Heading>
        <p>{t('invoices.noData.text')}</p>
      </div>
    </div>
  );
};

import {
  DateRangeSelector,
  DateRangeSelectorChangePayload,
} from '@clevergy/ui/components/DateRangeSelector';
import { useAnalyticsContext } from 'context/AnalyticsContext';
import { Locale } from 'date-fns';
import { useAnalysisPageFilters } from 'hooks/useAnalysisPageFilters';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC } from 'react';

export type AnalysisDatePickerProps = {
  texts: {
    day: string;
    month: string;
    year: string;
    custom: string;
    cancelButton: string;
    okButton: string;
  };
  locale: Locale;
};

export const AnalysisDatePicker: FC<AnalysisDatePickerProps> = (props) => {
  const { texts, locale } = props;

  const { track } = useAnalyticsContext();
  const { selectedHouseDateLimits } = useSelectedHouse();

  const {
    dateRangeType,
    dateRangeCurrentDate,
    customStartDate,
    customEndDate,
    setFilters,
  } = useAnalysisPageFilters();

  const handleDateRangeSelectorChange = (
    payload: DateRangeSelectorChangePayload,
  ) => {
    setFilters({
      dateRangeType: payload.dateRangeType,
      dateRangeCurrentDate: payload.dateRangeCurrentDate,
      customStartDate: payload.customStartDate,
      customEndDate: payload.customEndDate,
    });

    track({
      event_type: 'page_analysis_date_selected',
      event_properties: {
        range: payload.dateRangeType,
      },
    });
  };

  return (
    <>
      {selectedHouseDateLimits.minStartDate &&
        selectedHouseDateLimits.maxEndDate && (
          <DateRangeSelector
            locale={locale}
            dateRangeType={dateRangeType}
            dateRangeDefaultCurrentDate={selectedHouseDateLimits.maxEndDate}
            dateRangeCurrentDate={dateRangeCurrentDate}
            customStartDate={customStartDate}
            customEndDate={customEndDate}
            minStartDate={selectedHouseDateLimits.minStartDate}
            maxEndDate={selectedHouseDateLimits.maxEndDate}
            onChange={handleDateRangeSelectorChange}
            texts={texts}
          />
        )}
    </>
  );
};

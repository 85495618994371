/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { HouseSetupDetails } from './HouseSetupDetails';
import {
  HouseSetupDetailsFromJSON,
  HouseSetupDetailsFromJSONTyped,
  HouseSetupDetailsToJSON,
  HouseSetupDetailsToJSONTyped,
} from './HouseSetupDetails';
import type { HouseSetupHouseMetadata } from './HouseSetupHouseMetadata';
import {
  HouseSetupHouseMetadataFromJSON,
  HouseSetupHouseMetadataFromJSONTyped,
  HouseSetupHouseMetadataToJSON,
  HouseSetupHouseMetadataToJSONTyped,
} from './HouseSetupHouseMetadata';

/**
 *
 * @export
 * @interface HouseSetup
 */
export interface HouseSetup {
  /**
   *
   * @type {HouseSetupDetails}
   * @memberof HouseSetup
   */
  details: HouseSetupDetails;
  /**
   *
   * @type {HouseSetupHouseMetadata}
   * @memberof HouseSetup
   */
  houseMetadata: HouseSetupHouseMetadata;
}

/**
 * Check if a given object implements the HouseSetup interface.
 */
export function instanceOfHouseSetup(value: object): value is HouseSetup {
  if (!('details' in value) || value['details'] === undefined) return false;
  if (!('houseMetadata' in value) || value['houseMetadata'] === undefined)
    return false;
  return true;
}

export function HouseSetupFromJSON(json: any): HouseSetup {
  return HouseSetupFromJSONTyped(json, false);
}

export function HouseSetupFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): HouseSetup {
  if (json == null) {
    return json;
  }
  return {
    details: HouseSetupDetailsFromJSON(json['details']),
    houseMetadata: HouseSetupHouseMetadataFromJSON(json['houseMetadata']),
  };
}

export function HouseSetupToJSON(json: any): HouseSetup {
  return HouseSetupToJSONTyped(json, false);
}

export function HouseSetupToJSONTyped(
  value?: HouseSetup | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    details: HouseSetupDetailsToJSON(value['details']),
    houseMetadata: HouseSetupHouseMetadataToJSON(value['houseMetadata']),
  };
}

import { useSelectedHouse } from './useSelectedHouse';
import { UserSetUpUserStatusEnum } from '@clevergy/api-client';
import { useTenantSettings } from 'context/TenantSettingsContext';
import { useUserSetupContext } from 'context/UserSetupContext';

export const useSmartMeterIntegration = () => {
  const { userStatus } = useUserSetupContext();
  const { selectedHouse } = useSelectedHouse();
  const tenantSettings = useTenantSettings();

  const shouldSuggestSmartMeterIntegration =
    (userStatus === UserSetUpUserStatusEnum.Initial ||
      userStatus === UserSetUpUserStatusEnum.PendingRenewal) &&
    typeof selectedHouse !== 'undefined' &&
    !selectedHouse?.dataProviders?.solarInverter &&
    typeof tenantSettings.dpoEmail !== 'undefined' &&
    tenantSettings.dpoEmail !== null &&
    tenantSettings.dpoEmail !== '';

  const isSmartMeterIntegrationInProgress =
    userStatus === UserSetUpUserStatusEnum.AcceptedConditions;

  const shouldShowSmartMeterDraftModules =
    shouldSuggestSmartMeterIntegration &&
    !selectedHouse?.dataProviders?.smartMeter;

  return {
    shouldSuggestSmartMeterIntegration,
    shouldShowSmartMeterDraftModules,
    isSmartMeterIntegrationInProgress,
  };
};
